<!--提交记录-->
<template>
  <div id="SubmissionRecord">
    <a-form layout="inline">
      <a-form-item label="编程语言" style="min-width: 240px">
        <a-select
          :style="{ width: '320px' }"
          placeholder="请选择语言"
          v-model="selectedLanguage"
          @change="loadRecords"
        >
          <a-option value="">所有编程语言</a-option>
          <a-option v-for="(language, index) in languages" :key="index">
            {{ language }}
          </a-option>
        </a-select>
      </a-form-item>
    </a-form>
    <a-divider size="0" />
    <a-table
      :loading="loading"
      :columns="columns"
      :data="records"
      :size="'mini'"
      :hoverable="true"
      :pagination="pagination"
      @page-change="onPageChange"
    >
      <template #status="{ record }">
        <a-tag :color="handleColor(record.judgeInfo?.message)">
          {{
            (() => {
              switch (record?.judgeInfo?.message) {
                case "Accepted":
                  return "通过";
                case "Wrong Answer":
                  return "答案错误";
                case "Compile Error":
                  return "编译错误";
                case "Time Limit Exceeded":
                  return "超出时间限制";
                case "Memory Limit Exceeded":
                  return "超出内存限制";
                default:
                  return record?.judgeInfo?.message;
              }
            })()
          }}
        </a-tag>
      </template>
      <template #judgeTime="{ record }">
        <icon-schedule />
        {{
          record.judgeInfo.time === null ? "N/A" : `${record.judgeInfo.time} ms`
        }}
      </template>
      <template #judgeMemory="{ record }">
        <icon-bug />
        {{
          record.judgeInfo.memory === null
            ? "N/A"
            : `${record.judgeInfo.memory} KB`
        }}
      </template>
      <template #detail="{ record }">
        <a-button @click="toQuestionPage(record.id)">查看</a-button>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  QuestionControllerService,
  QuestionSubmitQueryRequest,
} from "../../generated";
import { useStore } from "vuex";
import { Message } from "@arco-design/web-vue";

const loading = ref(true);
const records = ref([]);

const selectedLanguage = ref<string>(""); // 绑定选中的语言
const route = useRoute();
const questionId = route.params.id as any;

const languages = ref<string[]>([]); // 存储所有可选的编程语言
const store = useStore();
const loginUser = store.state.user.loginUser;

// 分页查询参数
const total = ref(0);
const searchParams = ref<QuestionSubmitQueryRequest>({
  pageSize: 10,
  current: 1,
  questionId: questionId,
  language: selectedLanguage.value,
  userId: loginUser.id,
  sortField: "createTime",
  sortOrder: "descend",
});

const pagination = reactive({
  ...searchParams.value,
  showTotal: true,
  total: total,
});
const onPageChange = (current: number) => {
  pagination.current = current;
  searchParams.value.current = current;
  loadRecords();
};
// eslint-disable-next-line no-undef
const props = defineProps({
  shouldReload: Boolean, // 接收父组件传来的布尔值
});
// eslint-disable-next-line no-undef
const emit = defineEmits(["resetShouldReload"]);
onMounted(() => {
  loadLanguages(); // 加载可选的编程语言
  loadRecords();
});

watch(
  () => props.shouldReload,
  (newVal) => {
    if (newVal) {
      // 当 shouldReload 为 true 时重新加载记录
      loadRecords();
      emit("resetShouldReload", !props.shouldReload);
    }
  }
);

const loadLanguages = async () => {
  try {
    const res = await QuestionControllerService.getCodeLanguageUsingGet();
    if (res.code === 0) {
      languages.value = res.data as string[];
    } else {
      Message.error("加载编程语言失败：" + res.data);
    }
  } catch (error: any) {
    Message.error("加载编程语言失败：" + error.message);
  }
};

const loadRecords = async () => {
  loading.value = true;
  try {
    const res =
      await QuestionControllerService.listMyQuestionSubmitVoRecordUsingPost(
        searchParams.value
      );
    if (res.code === 0) {
      records.value = res.data.records;
      total.value = res.data.total;
    } else {
      Message.error("加载提交记录失败：" + res.message);
    }
  } catch (error: any) {
    Message.error("加载提交记录失败：" + error.message);
  } finally {
    loading.value = false;
  }
};
const columns = [
  {
    title: "所有状态",
    dataIndex: "status",
    slotName: "status",
  },
  {
    title: "所有语言",
    dataIndex: "language",
  },
  {
    title: "执行用时",
    slotName: "judgeTime",
  },
  {
    title: "内存消耗",
    slotName: "judgeMemory",
  },
  {
    title: "提交时间",
    dataIndex: "updateTime",
    sortable: {
      sortDirections: ["ascend", "descend"],
    },
  },
  {
    title: "详情",
    slotName: "detail",
  },
];
const router = useRouter();
const toQuestionPage = (questionSubmitId: number) => {
  router.push({
    path: `/view/question/submit/detail/${questionSubmitId}`,
  });
};
const handleColor = (record: string) => {
  if (record === "Accepted") {
    return "green";
  } else {
    return "red";
  }
};
</script>

<style scoped></style>
