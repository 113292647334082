<template>
  <a-row id="globalHeader" align="center" :wrap="false">
    <a-col flex="auto">
      <a-menu
        mode="horizontal"
        :selected-keys="selectedKeys"
        @menuItemClick="doMenuClick"
      >
        <a-menu-item
          key="0"
          :style="{ padding: 0, marginRight: '38px' }"
          disabled
        >
          <div class="title-bar">
            <img class="logo" src="../assets/yoj-logo.png" />
            <div class="title">YOJ</div>
          </div>
        </a-menu-item>
        <!-- 路由先过滤再展示-->
        <a-menu-item v-for="item in visibleRoutes" :key="item.path"
          >{{ item.name }}
        </a-menu-item>
      </a-menu>
    </a-col>
    <!-- 带图标的下拉菜单用来登录、注册和展示个人信息-->
    <a-col flex="100px">
      <div class="userAvatar">
        <a-dropdown trigger="hover">
          <a-avatar shape="circle">
            <template
              v-if="loginUser && loginUser.userRole !== ACCESS_ENUM.NOT_LOGIN"
            >
              <template v-if="loginUser.userAvatar">
                <img
                  class="avatarImg"
                  alt="avatar"
                  :src="loginUser.userAvatar"
                />
              </template>
              <template v-else>
                <a-avatar>
                  <IconUser />
                </a-avatar>
              </template>
            </template>
            <template v-else>
              <a-avatar>未登录</a-avatar>
            </template>
          </a-avatar>
          <template #content>
            <template
              v-if="loginUser && loginUser.userRole as string !== ACCESS_ENUM.NOT_LOGIN"
            >
              <a-doption>
                <template #icon>
                  <icon-idcard />
                </template>
                <template #default>
                  <a-anchor-link @click="toUserAboutPage"
                    >个人信息
                  </a-anchor-link>
                </template>
              </a-doption>
              <a-doption>
                <template #icon>
                  <icon-poweroff />
                </template>
                <template #default>
                  <a-anchor-link @click="logout">退出登录</a-anchor-link>
                </template>
              </a-doption>
            </template>
            <template v-else>
              <a-doption>
                <template #icon>
                  <icon-user />
                </template>
                <template #default>
                  <a-anchor-link @click="toUserLoginPage"
                    >用户登录
                  </a-anchor-link>
                </template>
              </a-doption>
              <a-doption>
                <template #icon>
                  <icon-user-add />
                </template>
                <template #default>
                  <a-anchor-link @click="toUserRegisterPage"
                    >用户注册
                  </a-anchor-link>
                </template>
              </a-doption>
            </template>
          </template>
        </a-dropdown>
      </div>
    </a-col>
  </a-row>
</template>

<script setup lang="ts">
import { routes } from "@/router/routes";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import checkAccess from "@/access/checkAccess";
import ACCESS_ENUM from "@/access/accessEnum";
import { LoginUserVO, UserControllerService } from "../../generated";

// 获取路由
const router = useRouter();
// 获取状态变量
const store = useStore();

// 展示在菜单的路由数组
// 这里使用计算属性，是为了动态计算变量的值，当登录用户信息发生变更时，会触发菜单栏的重新渲染，展示新增权限的菜单项
const visibleRoutes = computed(() => {
  return routes.filter((item, index) => {
    if (item.meta?.hideInMenu) {
      return false;
    }
    // 根据权限过滤菜单
    if (
      !checkAccess(store.state.user?.loginUser, item.meta?.access as string)
    ) {
      return false;
    }
    return true;
  });
});

// 默认主页
const selectedKeys = ref(["/"]);

// 路由跳转后，更新选中的菜单项
router.afterEach((to, from, failure) => {
  selectedKeys.value = [to.path];
});

const loginUser: LoginUserVO = computed(
  () => store.state.user?.loginUser
) as LoginUserVO;

/**
 * 退出登录
 */
const logout = () => {
  UserControllerService.userLogoutUsingPost();
  location.reload();
};

// setTimeout(() => {
//   store.dispatch("user/getLoginUser", {
//     userName: "嘉良管理员",
//     userRole: ACCESS_ENUM.ADMIN,
//   });
// }, 3000);
const doMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};

/**
 * 跳转到登录、注册、个人信息页面
 */
const toUserLoginPage = () => {
  router.push("/user/login");
};
const toUserRegisterPage = () => {
  router.push("/user/register");
};
const toUserAboutPage = () => {
  router.push("/about");
};
</script>

<style scoped>
.userAvatar {
  cursor: pointer;
}

.avatarImg {
  height: 160px;
  width: 160px;
  border-radius: 50%;
  object-fit: cover;
}

.title-bar {
  display: flex;
  align-items: center;
}

.title {
  color: #444;
  margin-left: 10px;
}

.logo {
  height: 58px;
}
</style>
