<!--题目提交页面-->
<template>
  <div id="QuestionsSubmitView">
    <a-form :model="searchParams" layout="inline">
      <a-form-item field="questionId" label="题号" style="min-width: 240px">
        <a-input
          v-model="searchParams.questionId"
          placeholder="请输入题目名称"
        />
      </a-form-item>
      <a-form-item field="language" label="编程语言" style="min-width: 240px">
        <a-select
          v-model="searchParams.language"
          :allow-clear="true"
          :style="{ width: '220px' }"
          placeholder="选择编程语言"
        >
          <a-option :key="language" v-for="language in codeLanguages"
            >{{ language }}
          </a-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="doSubmit">搜索</a-button>
      </a-form-item>
      <a-checkbox v-model="showMySubmit" @change="showMySubmitPage"
        >只看我的
      </a-checkbox>
    </a-form>
    <a-divider size="0" />
    <a-table
      :columns="columns"
      :data="dataList"
      :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total: total,
      }"
      @page-change="onPageChange"
    >
      <template #judgeTime="{ record }">
        <icon-schedule />
        {{
          record.judgeInfo.time === null ? "N/A" : `${record.judgeInfo.time} ms`
        }}
      </template>
      <template #judgeMemory="{ record }">
        <icon-bug />
        {{
          record.judgeInfo.memory === null
            ? "N/A"
            : `${record.judgeInfo.memory} KB`
        }}
      </template>
      <template #judgeStatus="{ record }">
        <a-tag :color="handleColor(record.judgeInfo?.message)">
          {{
            (() => {
              switch (record?.judgeInfo?.message) {
                case "Accepted":
                  return "通过";
                case "Wrong Answer":
                  return "答案错误";
                case "Compile Error":
                  return "编译错误";
                case "Time Limit Exceeded":
                  return "超出时间限制";
                case "Memory Limit Exceeded":
                  return "超出内存限制";
                default:
                  return record?.judgeInfo?.message;
              }
            })()
          }}
        </a-tag>
      </template>
      <template #optional="{ record }">
        <a-space>
          <a-button type="primary" @click="toQuestionSubmitPage(record.id)">
            查看
          </a-button>
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import {
  QuestionControllerService,
  QuestionSubmitQueryRequest,
} from "../../../generated";
import { useRouter } from "vue-router";
import { Message } from "@arco-design/web-vue";

// 指定table列名
const columns = [
  {
    title: "提交号",
    dataIndex: "id",
  },
  {
    title: "编程语言",
    dataIndex: "language",
  },
  {
    title: "执行用时",
    slotName: "judgeTime",
  },
  {
    title: "消耗内存",
    slotName: "judgeMemory",
  },
  {
    title: "判题状态",
    slotName: "judgeStatus",
  },
  {
    title: "题目id",
    dataIndex: "questionId",
  },
  {
    title: "提交者id",
    dataIndex: "userId",
  },
  {
    title: "提交时间",
    dataIndex: "createTime",
    sortable: {
      sortDirections: ["ascend", "descend"],
    },
  },
  {
    slotName: "optional",
  },
];
const handleColor = (record: any): string => {
  if (record === "Accepted") {
    return "green";
  } else {
    return "red";
  }
};

const router = useRouter();
const dataList = ref([]);
// 分页参数
const total = ref(0);
const searchParams = ref<QuestionSubmitQueryRequest>({
  questionId: undefined,
  language: undefined,
  pageSize: 10,
  current: 1,
});
const codeLanguages = ref(["java"]);
const showMySubmit = ref(false);
const showMySubmitPage = () => {
  loadData();
};
const loadData = async () => {
  let res;
  if (showMySubmit.value) {
    res = await QuestionControllerService.listMyQuestionSubmitVoRecordUsingPost(
      {
        ...searchParams.value,
        sortField: "createTime",
        sortOrder: "descend",
      }
    );
  } else {
    res = await QuestionControllerService.listQuestionSubmitByPageUsingPost({
      ...searchParams.value,
      sortField: "createTime",
      sortOrder: "descend",
    });
  }
  if (res.code === 0) {
    dataList.value = res.data.records;
    total.value = res.data.total;
  } else {
    Message.error("加载失败：" + res.message);
  }
};

/**
 * 页面加载时，请求数据
 */
onMounted(async () => {
  await loadData();
  // 获取编程语言
  const res = await QuestionControllerService.getCodeLanguageUsingGet();
  if (res.code === 0 && res.data) {
    codeLanguages.value = res.data;
  }
});

const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
  loadData();
};

/**
 * 查看自己的提交详情
 */
const toQuestionSubmitPage = (questionSubmitId: number) => {
  router.push({
    path: `/view/question/submit/detail/${questionSubmitId}`,
  });
};
/**
 * 搜索题目
 */
const doSubmit = () => {
  // 重置搜索页号
  searchParams.value = {
    ...searchParams.value,
    current: 1,
  };
  loadData();
};
</script>

<style scoped>
#QuestionsSubmitView {
  max-width: 1180px;
  margin: 0 auto;
}
</style>
