<!--题目管理页面-->
<template>
  <div id="manageQuestionView">
    <a-button @click="router.push({ path: '/add/question' })" type="primary"
      >创建题目
    </a-button>
    <a-divider size="0" />
    <a-table
      :columns="columns"
      :data="dataList"
      :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total: total,
      }"
      @page-change="onPageChange"
    >
      <template #tags="{ record }">
        <a-space wrap>
          <a-tag
            v-for="(tag, index) of JSON.parse(record.tags)"
            :key="index"
            :tag="tag"
            color="arcoblue"
            >{{ tag }}
          </a-tag>
        </a-space>
      </template>
      <template #timeLimit="{ record }">
        {{
          record.judgeConfig
            ? JSON.parse(record.judgeConfig)?.timeLimit !== undefined
              ? `${JSON.parse(record.judgeConfig).timeLimit} ms`
              : "N/A"
            : "N/A"
        }}
      </template>
      <template #memoryLimit="{ record }">
        {{
          record.judgeConfig
            ? JSON.parse(record.judgeConfig)?.memoryLimit !== undefined
              ? `${JSON.parse(record.judgeConfig).memoryLimit} KB`
              : "N/A"
            : "N/A"
        }}
      </template>
      <template #stackLimit="{ record }">
        {{
          record.judgeConfig
            ? JSON.parse(record.judgeConfig)?.stackLimit !== undefined
              ? `${JSON.parse(record.judgeConfig).stackLimit} KB`
              : "N/A"
            : "N/A"
        }}
      </template>
      <template #optional="{ record }">
        <a-space>
          <a-button type="primary" @click="doUpdate(record)">修改</a-button>
          <a-popconfirm
            content="确定要删除此题目吗?"
            type="error"
            okText="是"
            cancelText="否"
            @ok="doDelete(record)"
          >
            <a-button status="danger" type="outline">删除</a-button>
          </a-popconfirm>
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { Question, QuestionControllerService } from "../../../generated";
import { useRouter } from "vue-router";
import { Message } from "@arco-design/web-vue";

// 指定table列名
const columns = [
  {
    title: "序号",
    dataIndex: "id",
    align: "center",
  },
  {
    title: "标题",
    dataIndex: "title",
  },
  {
    title: "标签",
    slotName: "tags",
  },
  {
    title: "时间限制",
    // dataIndex: "timeLimit",
    slotName: "timeLimit",
    // width: 100,
  },
  {
    title: "内存限制",
    slotName: "memoryLimit",
    // width: 100,
  },
  {
    title: "堆栈限制",
    slotName: "stackLimit",
  },
  {
    title: "提交数",
    dataIndex: "submitNum",
  },
  {
    title: "通过数",
    dataIndex: "acceptedNum",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    sortable: {
      sortDirections: ["ascend", "descend"],
    },
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
  },
  {
    title: "创建用户",
    dataIndex: "userId",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];
const router = useRouter();
const dataList = ref([]);
// 分页参数
const total = ref(0);
const searchParams = ref({
  pageSize: 8,
  current: 1,
});
const loadData = async () => {
  const res = await QuestionControllerService.listQuestionByPageUsingPost(
    searchParams.value
  );
  console.log("loadData:", res);
  if (res.code === 0) {
    dataList.value = res.data.records;
    total.value = res.data.total;
  } else {
    Message.error("加载失败" + res.message);
  }
};

/**
 * 页面加载时，请求数据
 */
onMounted(() => {
  loadData();
});

const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
  loadData();
};

/**
 * 删除操作
 * @param question
 */
const doDelete = async (question: Question) => {
  const res = await QuestionControllerService.deleteQuestionUsingPost({
    id: question.id,
  });
  if (res.code === 0) {
    Message.success("删除成功");
    await loadData();
  } else {
    Message.error("删除失败，" + res.message);
  }
};

/**
 * 修改操作
 * @param question
 */
const doUpdate = (question: Question) => {
  router.push({
    path: "/update/question",
    query: {
      id: question.id,
    },
  });
};
</script>

<style scoped>
#manageQuestionView {
  max-width: 1360px;
  margin: 0 auto;
}
</style>
