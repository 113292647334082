<template>
  <Viewer :value="value" :plugins="plugins" />
</template>

<script setup lang="ts">
import gfm from "@bytemd/plugin-gfm";
import highlight from "@bytemd/plugin-highlight";
import { Editor, Viewer } from "@bytemd/vue-next";
import { withDefaults, defineProps } from "vue";

/**
 * 定义组件属性类型
 */
interface Props {
  value: string;
}

const plugins = [gfm(), highlight()];

/**
 * 给组件设置初始值，接收父组件传过来的值,
 * 需要注意defineProps只能在setup语法糖里面使用，不需要import引入
 */
const props = withDefaults(defineProps<Props>(), {
  value: () => "",
});
</script>
<style></style>
