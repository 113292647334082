<!--做题页面-->
<template>
  <div id="viewQuestionView">
    <a-row :gutter="[24, 24]">
      <a-col :md="11" :xs="24">
        <!--标签页 tabs-->
        <a-tabs default-active-key="question">
          <a-tab-pane key="question" title="题目">
            <a-scrollbar type="track" style="height: 70vh; overflow: auto">
              <a-card v-if="question" :title="question.title">
                <a-descriptions
                  title="判题条件"
                  :column="{ xs: 1, md: 2, lg: 3 }"
                >
                  <a-descriptions-item label="时间限制">
                    {{ question.judgeConfig?.timeLimit ?? 0 }}
                  </a-descriptions-item>
                  <a-descriptions-item label="内存限制">
                    {{ question.judgeConfig?.memoryLimit ?? 0 }}
                  </a-descriptions-item>
                  <a-descriptions-item label="堆栈限制">
                    {{ question.judgeConfig?.stackLimit ?? 0 }}
                  </a-descriptions-item>
                </a-descriptions>
                <MdViewer :value="question.content || '空'" />
                <template #extra>
                  <a-tag
                    v-for="(tag, index) of question.tags"
                    :key="index"
                    :tag="tag"
                    color="green"
                    >{{ tag }}
                  </a-tag>
                </template>
              </a-card>
            </a-scrollbar>
          </a-tab-pane>
          <a-tab-pane key="comment" title="评论"> 评论区</a-tab-pane>
          <a-tab-pane key="mySubmit" title="我的提交">
            <SubmissionRecord
              :shouldReload="shouldReload"
              @resetShouldReload="resetShouldReload"
            />
          </a-tab-pane>
        </a-tabs>
      </a-col>
      <a-col :md="13" :xs="24">
        <a-form :model="form" layout="inline">
          <a-form-item field="title" label="编程语言" style="min-width: 240px">
            <!--  编程语言改为从后端获取-->
            <a-select
              v-model="form.language"
              :style="{ width: '220px' }"
              placeholder="选择编程语言"
            >
              <a-option :key="language" v-for="language in codeLanguages"
                >{{ language }}
              </a-option>
            </a-select>
          </a-form-item>
        </a-form>
        <CodeEditor
          :value="form.code"
          :language="form.language"
          :handle-change="changeCode"
        />
        <a-divider size="0" />
        <a-button
          type="primary"
          size="medium"
          style="min-width: 120px"
          @click="doSubmit"
          >自测
        </a-button>
        <a-button
          type="primary"
          style="min-width: 120px; margin-left: 10px"
          @click="doSubmit"
          >提交
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script setup lang="ts">
import { defineProps, onMounted, ref, withDefaults } from "vue";
import {
  QuestionControllerService,
  QuestionSubmitAddRequest,
  QuestionVO,
} from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import CodeEditor from "@/components/CodeEditor.vue";
import MdViewer from "@/components/MdViewer.vue";
import { Message } from "@arco-design/web-vue";
import SubmissionRecord from "@/components/SubmissionRecord.vue";

interface Props {
  id: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: () => "",
});

const question = ref<QuestionVO>();

const loadData = async () => {
  const res = await QuestionControllerService.getQuestionVoByIdUsingGet(
    props.id as any
  );
  if (res.code === 0) {
    question.value = res.data;
  } else {
    message.error("加载失败" + res.data);
  }
};
const codeLanguages = ref(["java"]);
const form = ref<QuestionSubmitAddRequest>({
  language: "java",
  code:
    "import java.util.Scanner;\n\n" +
    "public class Main {\n" +
    "    public static void main(String[] args){\n" +
    "        Scanner sc = new Scanner(System.in);\n" +
    "        int a = sc.nextInt();\n" +
    "        int b = sc.nextInt();\n" +
    "        System.out.println(a + b);\n" +
    "    }\n" +
    "}",
});
const shouldReload = ref(false);
/**
 * 提交代码
 */
const doSubmit = async () => {
  if (!question.value?.id) {
    return;
  }
  if (!form.value?.code) {
    Message.error("请输入代码后提交");
    return;
  }
  const res = await QuestionControllerService.doQuestionSubmitUsingPost({
    ...form.value,
    questionId: question.value.id,
  });
  shouldReload.value = true;
  if (res.code === 0) {
    message.success("提交成功");
  } else {
    message.error("提交失败：" + res.message);
  }
};
const resetShouldReload = (value: boolean) => {
  shouldReload.value = value;
};
const changeCode = (value: string) => {
  form.value.code = value;
};

/**
 * 页面加载时，请求数据
 */
onMounted(async () => {
  await loadData();
  // 获取编程语言
  const res = await QuestionControllerService.getCodeLanguageUsingGet();
  if (res.code === 0 && res.data) {
    codeLanguages.value = res.data;
  }
});
</script>

<style scoped>
#viewQuestionView {
  max-width: 1400px;
  margin-left: 10px;
}
</style>
