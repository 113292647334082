// 存放权限管理的代码
import router from "@/router";
import store from "@/store";
import checkAccess from "@/access/checkAccess";
import ACCESS_ENUM from "@/access/accessEnum";

// 路由拦截
router.beforeEach(async (to, from, next) => {
  // 获取用户登录信息
  let loginUser = store.state.user.loginUser;
  console.log("登录用户信息", loginUser);

  // 如果用户没登录，则自动登录
  if (!loginUser || !loginUser.userRole) {
    // 加 await 是为了等用户登录成功之后，再执行后续的代码
    await store.dispatch("user/getLoginUser");
    // 更新用户登录信息
    loginUser = store.state.user.loginUser;
  }
  // 没有权限权限默认为未登录
  const needAccess = (to.meta?.access as string) ?? ACCESS_ENUM.NOT_LOGIN;

  // 要跳转的页面必须登录
  if (needAccess !== ACCESS_ENUM.NOT_LOGIN) {
    // 如果没登录，跳转到登录页面
    console.log("needAccess", needAccess);
    if (
      !loginUser ||
      !loginUser.userRole ||
      loginUser.userRole === ACCESS_ENUM.NOT_LOGIN
    ) {
      next(`/user/login?redirect=${to.fullPath}`);
      return;
    }
    // 如果已经登录了，但是权限不足，那么跳转到无权限页面
    if (!checkAccess(loginUser, needAccess)) {
      next("/noAuth");
      return;
    }
  }
  next();
});
